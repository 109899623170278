<template>
    <div class="add-representative">
      <div class="add-representative__title">
        {{ !editRepresentative ? $t('add_edit_representaive.new_representaive') : $t('add_edit_representaive.edit_representative_id') + editRepresentative.ba_id}}
      </div>
      <form class="add-representative__form" :class="{'locale-usa':locale==='USA'}">

         <!-- Full name block -->
         <div
            class="form-input"
            :class="
          [
            {'form-input--full' : form.full_name},
            {'form-input--error' : errors.full_name || (serverErrors && serverErrors.full_namel)}
          ]"
        >
          <input v-model="form.full_name" type="text" required :disabled="editRepresentative">
          <span class="form-input__label">
            {{ $t('add_edit_representaive.name') }}*
          </span>
          <div v-if="errors.full_name" class="form-input__error" v-text="errors.full_name" />
          <div v-if="serverErrors && serverErrors.name" class="form-input__error">
            <p v-for="(err, index) in serverErrors.name" :key="index">
              {{ err }}
            </p>
          </div>
        </div>
        <!-- Email block -->

        <div class="form-input" :class="[{'form-input--full' : form.email},{'form-input--error' : errors.email || (serverErrors && serverErrors.email)}]">
          <input v-model="form.email" type="text" required :disabled="(currentRole !== 'admin' && currentRole !== 'manager' && currentRole !== 'user') || editRepresentative">
          <span class="form-input__label"> {{ $t('email') }}* </span>
          <div v-if="errors.email" class="form-input__error" v-text="errors.email" />
          <div v-if="serverErrors && serverErrors.email" class="form-input__error">
            <p v-for="(err, index) in serverErrors.email" :key="index">
              {{ err }}
            </p>
          </div>
        </div>
        <!-- Phone block -->
        <div 
        class="form-input" 
        :class="
        [
          {'form-input--full' : form.phone},
          {'form-input--error' : errors.phone || (authErrors && authErrors.phone)}
        ]"
      >
        <input v-if="locale==='RU'" v-model="form.phone" type="tel" required :disabled="editRepresentative">
        <input v-if="locale==='USA'" v-model="form.phone" type="tel" v-mask="'+############'" required :disabled="editRepresentative">
        <span class="form-input__label">{{ $t('phone') }}*</span>
        <div v-if="errors.phone" class="form-input__error" v-text="errors.phone" />
        <div v-if="authErrors && authErrors.phone" class="form-input__error">
          <p v-for="(err, index) in authErrors.phone" :key="index">
            {{ err }}
          </p>
        </div>
      </div>
        <!-- Password -->
        <div
            class="form-input"
            :class="
          [
            {'form-input--full' : form.password},
            {'form-input--error' : errors.password || (serverErrors && serverErrors.password)}
          ]"
          v-if="!editRepresentative"
        >
          <input
              v-model="form.password"
              :type="isHidePassword ? 'password' : 'text'"
              :disabled="currentRole !== 'manager' && currentRole !== 'admin' && currentRole !== 'user'"
              @focus="checkingPassword = true"
              @blur="checkingPassword = false"
          >
          <span class="form-input__label">
            {{ $t('password') }}*
          </span>
          <div class="form-input__icon" @click="isHidePassword=!isHidePassword">
            <img v-if="isHidePassword" src="@/assets/icons/icon-eye-closed.svg">
            <img v-else src="@/assets/icons/icon-eye.svg">
          </div>
          <div v-if="errors.password" class="form-input__error" v-text="errors.password" />
          <div v-if="serverErrors && serverErrors.password" class="form-input__error">
            <p v-for="(err, index) in serverErrors.password" :key="index">
              {{ err }}
            </p>
          </div>
          <div v-if="checkingPassword" class="form-input__info">
            <div
                class="form-input__info-item"
                :class="{'form-input__info-item--success' : form.password && form.password.length > 5}"
            >
              {{ $t('add_edit_user.minimum_symbols') }}
            </div>
            <div
                class="form-input__info-item"
                :class="{'form-input__info-item--success' : isContainNumber}"
            >
              {{ $t('add_edit_user.minimum_numbers') }}
            </div>
            <div
                class="form-input__info-item"
                :class="{'form-input__info-item--success' : isContainLetter}"
            >
              {{ $t('add_edit_user.minimum_letters') }}
            </div>
            <div
                class="form-input__info-item"
                :class="{'form-input__info-item--success' : isContainBigLetter}"
            >
              {{ $t('add_edit_user.minimum_capital_letters') }}
            </div>
          </div>
        </div>
        <!-- confirm password -->
        <div
            class="form-input"
            :class="
          [
            {'form-input--full' : form.password_confirmation},
            {'form-input--error' : errors.password_confirmation || (serverErrors && serverErrors.password_confirmation)}
          ]"
          v-if="!editRepresentative"
        >
          <input 
            v-model="form.password_confirmation" 
            :type="isHidePassword ? 'password' : 'text'" 
            :disabled="currentRole !== 'manager' && currentRole !== 'admin' && currentRole !== 'user'">
          <span class="form-input__label">
            {{ $t('add_edit_representaive.repeat_password') }}*
          </span>
          <div v-if="errors.password_confirmation" class="form-input__error" v-text="errors.password_confirmation" />
          <div v-if="serverErrors && serverErrors.password_confirmation" class="form-input__error">
            <p v-for="(err, index) in serverErrors.password_confirmation" :key="index">
              {{ err }}
            </p>
          </div>
        </div>

        <!-- BA block -->
        <!-- <div
            class="form-input"
            :class="
          [
            {'form-input--full' : form.business_account},
            {'form-input--error' : errors.business_account || (serverErrors && serverErrors.business_account)}
          ]"
        >
          <input v-model="form.business_account" type="text" required>
          <span class="form-input__label">
            {{ $t('add_edit_representaive.business_account') }}*
          </span>
          <div v-if="errors.business_account" class="form-input__error" v-text="errors.business_account" />
          <div v-if="serverErrors && serverErrors.business_account_id" class="form-input__error">
            <p v-for="(err, index) in serverErrors.business_account_id" :key="index">
              {{ err }}
            </p>
          </div>
        </div> -->
        <!-- End of the BA block -->
  
        <div class="form-checkbox">
          <!-- Viewing ballance -->
          <div class="form-input">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  v-model="form.ba_ballance"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_representaive.viewing_ballance') }}
              </span>
            </span>
            </label>
          </div>
          <!-- Viewing statistics -->
          <!-- <div class="form-input">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  v-model="form.viewing_statistics"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_representaive.viewing_statistics') }}
              </span>
            </span>
            </label>
          </div> -->
          <div v-for="permission in parsePermissions" :key="permission.formString" class="form-input">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  v-model="form[permission.formString]"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ permission.title }} {{ permission.name }}
              </span>
            </span>
            </label>
          </div>
          <!-- Access telegram -->
          <!-- <div class="form-input">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  v-model="form.access_telegram"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_representaive.access_telegram') }}
              </span>
            </span>
            </label>
          </div> -->
          <!-- Replenishment telegram -->
          <!-- <div class="form-input">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  v-model="form.replenishment_telegram"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_representaive.replenishment_telegram') }}
              </span>
            </span>
            </label>
          </div> -->
          <!-- Access vkads -->
          <!-- <div class="form-input">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  v-model="form.access_vkads"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_representaive.access_vkads') }}
              </span>
            </span>
            </label>
          </div> -->
          <!-- Replenishment vkads -->
          <!-- <div class="form-input">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  v-model="form.replenishment_vkads"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_representaive.replenishment_vkads') }}
              </span>
            </span>
            </label>
          </div> -->
          <!-- Access yandexDirect -->
          <!-- <div class="form-input">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  v-model="form.access_yandexDirect"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_representaive.access_yandexDirect') }}
              </span>
            </span>
            </label>
          </div> -->
          <!-- Replenishment yandexDirect -->
          <!-- <div class="form-input">
            <label class="input__item-checkbox">
              <input
                  type="checkbox"
                  v-model="form.replenishment_yandexDirect"
              >
              <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
              <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('add_edit_representaive.replenishment_yandexDirect') }}
              </span>
            </span>
            </label>
          </div> -->
        </div>
        <div v-if="errors.permissions" class="form-input__error checkbox_errors" v-text="errors.permissions" />
        <div v-if="errors.forbidden" class="form-input__error checkbox_errors" v-text="errors.forbidden" />
        <div v-if="isRepresentativeExist" class="add-representative__if-exists">
          <p>
            {{ $t('add_edit_representaive.exist') }}
          </p>
          <div class="buttons-group">
            <button-element @click-button="send" :text="$t('button_props.yes')"/>
            <button-element @click-button="closeDialog" :text="$t('button_props.no')"/>
          </div>
        </div>
        <div v-else class="add-representative__buttons-group">
          <button-element @click-button="send" :text="!editRepresentative ? $t('add_edit_user.create') : $t('button_props.save')"/>
          <button-element data-role="remove" v-if="editRepresentative" @click-button="unbindRepresentative" :text="$t('delete')"/>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import formValidate from "@/mixin/formValidate";
  import ButtonElement from "../elements/ButtonElement";
  
  export default {
    name: 'AddEditRepresentative',
    components: {ButtonElement},
    mixins: [formValidate],
    data() {
      return {
        locale: process.env.VUE_APP_LOCALE,
        form: {
          email: '',
          password: '',
          password_confirmation: '',
          full_name: '',
          business_account: '',
          ba_ballance: false,
          viewing_statistics: false,
          access_mytarget: false,
          replenishment_mytarget: false,
          access_vkontakte: false,
          replenishment_vkontakte: false,
          access_facebook: false,
          replenishment_facebook: false,
          access_yandex: false,
          replenishment_yandex: false,
          access_google: false,
          replenishment_google: false,
          access_vkads: false,
          replenishment_vkads: false,
          access_promopages: false,
          replenishment_promopages: false,
          access_avito: false,
          replenishment_avito: false,
          access_vkmarket: false,
          replenishment_vkmarket: false,
          access_metricapro: false,
          replenishment_metricapro: false,
          access_telegram: false,
          replenishment_telegram: false,
          access_tiktok: false,
          replenishment_tiktok: false,
          phone: '',
        },
        errors: {
          email: '',
          password: '',
          password_confirmation: '',
          full_name: '',
          business_account: '',
          phone: '',
          permissions: '',
          forbidden: ''
        },
        role: {
          finance: this.$t('add_edit_user.role.0'),
          admin: this.$t('add_edit_user.role.1'),
          manager: this.$t('add_edit_user.role.2'),
          user: this.$t('add_edit_user.role.3'),
        },
        checkingPassword: false,
        isContainNumber: false,
        isContainLetter: false,
        isContainBigLetter: false,
        isRepresentativeExist: false
      }
    },
    computed: {
      currentPassword() {
        return this.form.password
      },
      editRepresentative() {
        return this.$store.state.Representatives.editRepresentative
      },
      serverErrors() {
        return this.$store.state.Representatives.errors
      },
      currentRole() {
        return this.$store.state.Auth.role
      },
      activeAccount() {
        return this.$store.state.Auth.activeAccount
      },
      permissions() {
        return this.$store.state.Representatives.permissions
      },
      platformsList() {
        return this.$store.state.Ad.platformsList || [];
      },
      parsePermissions() {
        return this.permissions.reduce((acc, permission) => {
          if (permission.startsWith('advert')) {
            const parts = permission.split('.');
            const platform = parts[1];
            const formString = parts[2] === 'view' ? `access_${parts[1]}` : `replenishment_${parts[1]}`;
            const platformItem = this.platformsList.find((item) => item.slug === platform);
            const name = platformItem ? platformItem.name : platform;
            const title = parts[2] === 'view' ? this.$t('add_edit_representaive.access') : this.$t('add_edit_representaive.replenishment');
            acc.push({ platform, title, name, formString });
          }
          return acc;
        }, []);
      }
    },
    watch: {
      currentPassword(val) {
        const regNumber = /^(?=.*\d).+$/
        this.isContainNumber = regNumber.test(String(val))
        const regLetter = /^(?=.*[a-z]).+$/
        this.isContainLetter = regLetter.test(String(val))
        const regBigLetter = /^(?=.*[A-Z]).+$/
        this.isContainBigLetter = regBigLetter.test(String(val))
      },
      form: {
        deep: true,
        handler () {
          this.$store.commit('Representatives/setData', { label: 'errors', data: null })
        }
      },
    },
    mounted() {
      this.form.email = this.editRepresentative ? this.editRepresentative.email : ''
      this.form.phone = this.editRepresentative ? this.editRepresentative.phone : ''
      // this.form.business_account = this.editRepresentative ? this.editRepresentative.ba_id : ''
      this.form.full_name = this.editRepresentative ? this.editRepresentative.name: ''
      const token = localStorage.getItem('token') ? localStorage.getItem('token') : localStorage.getItem('client_token') 
      this.$store.dispatch('Representatives/getPermissions', { token })
      // this.createPermissions()
      if(this.editRepresentative) {
        this.updateFormPermissions()
      }
    },
    beforeDestroy() {
      this.form.email = ''
      this.form.password = ''
      this.form.password_confirmation = ''
      // this.form.business_account = ''
      this.form.full_name = ''
      this.form.ba_ballance = false,
      this.form.viewing_statistics = false
      this.$store.commit('Representatives/setData', { label: 'editRepresentative', data: null })
    },
    methods: {
      send() {
          this.validateForm()
          if (this.isError) {
            return false
          }
  
          const dataRepresentative = {
            business_account_id: this.activeAccount.id,
            name: this.form.full_name,
            phone: this.phoneFormat(this.form.phone),
            email: this.form.email,
            password: this.form.password,
            permissions: [
              this.form.ba_ballance && "ba.balance.view",
              // this.form.viewing_statistics && "ba.statistics.view",
              this.form.access_mytarget && "advert.mytarget.view",
              this.form.replenishment_mytarget && "advert.mytarget.finance",
              this.form.access_vkontakte && "advert.vkontakte.view",
              this.form.replenishment_vkontakte && "advert.vkontakte.finance",
              this.form.access_facebook && "advert.facebook.view",
              this.form.replenishment_facebook && "advert.facebook.finance",
              this.form.access_yandex && "advert.yandex.view",
              this.form.replenishment_yandex && "advert.yandex.finance",
              this.form.access_google && "advert.google.view",
              this.form.replenishment_google && "advert.google.finance",
              this.form.access_vkads && "advert.vkads.view",
              this.form.replenishment_vkads && "advert.vkads.finance",
              this.form.access_promopages && "advert.promopages.view",
              this.form.replenishment_promopages && "advert.promopages.finance",
              this.form.access_avito && "advert.avito.view",
              this.form.replenishment_avito && "advert.avito.finance",
              this.form.access_vkmarket && "advert.vkmarket.view",
              this.form.replenishment_vkmarket && "advert.vkmarket.finance",
              this.form.access_metricapro && "advert.metricapro.view",
              this.form.replenishment_metricapro && "advert.metricapro.finance",
              this.form.access_telegram && "advert.telegram.view",
              this.form.replenishment_telegram && "advert.telegram.finance",
              this.form.access_tiktok && "advert.tiktok.view",
              this.form.replenishment_tiktok && "advert.tiktok.finance"

            ].filter(Boolean)
          }
  
          let type = this.editRepresentative || this.isRepresentativeExist ? 'Representatives/editRepresentative' : 'Representatives/createRepresentative'
          // const formattedRepresentative = this.removeEmptyKeys(dataRepresentative)
          const data = { representative: dataRepresentative }
          const token = localStorage.getItem('token') ? localStorage.getItem('token') : localStorage.getItem('client_token') 
          this.$store.dispatch(type, {data:data, token})
            .then(() => {
              this.successRequest()
            })
            .catch(err => {
              console.log(err);
              if (!err.response.data.success && err.response.data.errors.email.length > 0) {
                const errorMessage = err.response.data.errors.email[0];
                const errorMessagesByLocale = {
                  RU: {
                    'Такое значение поля E-mail уже существует.': 'isRepresentativeExist',
                  },
                  USA: {
                    'The E-Mail address has already been taken.': 'isRepresentativeExist',
                  },
                };
                if (errorMessagesByLocale[this.locale]?.[errorMessage]) {
                  this[errorMessagesByLocale[this.locale][errorMessage]] = true;
                } else {
                  this.errors.forbidden = errorMessage;
                }
              }
            });
        
      },
      successRequest() {
        const params = {
          // role: 'partner',
          per_page: 10,
          // email: this.$route.query.email
        }
        const token = localStorage.getItem('token') ? localStorage.getItem('token') : localStorage.getItem('client_token')
        this.$store.dispatch('Representatives/getRepresentativesList', { params, token, id: this.activeAccount.id })
        this.$notify({
          message: this.$t('changes_saved'),
          type: 'success'
        });
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
        setTimeout(() => {
          this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
        }, 200)
      },
      validateForm () {
        if (!this.form.email) {
          this.errors.email = this.$t('forms_notifies.error_mail')
        } else {
          this.validateEmail()
        }
        if (!this.form.full_name) {
          this.errors.full_name = this.$t('forms_notifies.full_name')
        } 
        if (!this.editRepresentative && !this.form.password) {
          this.errors.password = this.$t('add_edit_representaive.input_password')
        }
        if (this.form.password && !this.form.password_confirmation) {
          this.errors.password_confirmation = this.$t('add_edit_representaive.password_repeat')
        } else if (this.form.password_confirmation !== this.form.password) {
          this.errors.password_confirmation = this.$t('add_edit_representaive.passwords_dont_match')
        } 
        // if (!this.form.business_account) {
        //   this.errors.business_account = this.$t('forms_notifies.business_account')
        // }
        if (!this.form.phone) {
          this.errors.phone = this.$t('add_edit_representaive.add_phone')
        } else {
          const regPhone = this.locale==='RU'? (/^(\+7\(?\d{3}\)?\d{3}-?\d{2}-?\d{2})$/) : (/^(\+)?([0-9]+)$/)
          if (!regPhone.test(String(this.form.phone))) {
            this.errors.phone = this.$t('add_edit_user.correct_number')
          }
        }
        if(!this.form.ba_ballance &&
          !this.form.viewing_statistics &&
          !this.form.access_mytarget &&
          !this.form.replenishment_mytarget &&
          !this.form.access_vkontakte &&
          !this.form.replenishment_vkontakte &&
          !this.form.access_facebook &&
          !this.form.replenishment_facebook &&
          !this.form.access_yandex &&
          !this.form.replenishment_yandex &&
          !this.form.access_google &&
          !this.form.replenishment_google &&
          !this.form.access_vkads &&
          !this.form.replenishment_vkads &&
          !this.form.access_promopages &&
          !this.form.replenishment_promopages &&
          !this.form.access_avito &&
          !this.form.replenishment_avito &&
          !this.form.access_vkmarket &&
          !this.form.replenishment_vkmarket &&
          !this.form.access_metricapro &&
          !this.form.replenishment_metricapro &&
          !this.form.access_telegram &&
          !this.form.replenishment_telegram &&
          !this.form.access_tiktok &&
          !this.form.replenishment_tiktok
        ) {
          this.errors.permissions = this.$t('add_edit_representaive.permissions')
        }
      },
      phoneFormat(val) {
        const regex = /[()\-\s]/g;
        if (val) {
          const result = val.replace(regex, '');
          return result;
        }
      },
      removeEmptyKeys(obj) {
        let result = {};
        for (let key in obj) {
          if (obj[key] !== "" && obj[key] !== null) {
            if (typeof obj[key] === "object") {
              result[key] = this.removeEmptyKeys(obj[key]);
            } else {
              result[key] = obj[key];
            }
          }
        }
        return result;
      },
      createPermissions() {
        const permissionsList = this.permissions.reduce((acc, permission) => {
          if (permission.startsWith('advert')) {
            const parts = permission.split('.');
            const platform = parts[1];
            const title = parts[2] === 'view' ? 'Доступ' : 'Пополнение';
            acc.push({ platform, title });
          }
          return acc;
        }, []);
        return permissionsList
      },
      unbindRepresentative () {
        const data = { 
          business_account_id: this.editRepresentative.ba_id, 
          email: this.editRepresentative.email
        }
        this.$store.dispatch('Representatives/unbindRepresentative', { data, token: localStorage.getItem('token') })
        .then(() => {
          this.successRequest()
        })
      },
      updateFormPermissions() {
        // Обновление разрешений на основе editRepresentative.permissions
        this.editRepresentative.permissions.forEach(permission => {
          if (permission === 'ba.balance.view') {
            this.$set(this.form, 'ba_ballance', true);
          } else {
            const [type, platform, action] = permission.split('.');
            if (type === 'advert') {
              if(action === 'view') {
                this.$set(this.form, `access_${platform}`, true);
              }
              if(action === 'finance') {
                this.$set(this.form, `replenishment_${platform}`, true);
              }
            }
          }
        });
      },
      closeDialog() {
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
        setTimeout(() => {
          this.$store.commit('Dialog/setData', { label: 'activeDialog', data: '' })
        }, 200)
      }
    }
    
  }
  </script>
  
  <style scoped lang="scss">
  @import "@/assets/styles/helpers/variables";
  @import "@/assets/styles/helpers/mixin";
  .add-representative {
    background: #fff;
    color: $basicBlack;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 8rem 8.5rem;
  
    &__title {
      font-weight: 700;
      font-size: 3rem;
      line-height: 3.5rem;
      margin-bottom: 3rem;
    }

    &__buttons-group {
      margin-top: 3rem;
      display: flex;
      gap: 2rem;
      button {
        margin-top: 0;
      };
      button[data-role="remove"] {
        background: #fff;
        border-color: var(--primary);
        color: var(--primary);
      }
    }
    &__if-exists{
      margin-top: 3rem;
      display: flex;
      gap: 2rem;
      flex-direction: column;
      .buttons-group {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        button {
          margin: 0;
        }
      }
    }
    .button {
      width: 50%;
    }
  
  
    @include below(993px) {
      padding: 40px;
  
      &__title {
        font-size: 24px;
        margin-bottom: 24px;
      }
    }
  
    @include below(576px) {
      padding: 40px 16px;
      justify-content: center;
  
      &__title {
        font-size: 18px;
        line-height: 1.4;
        text-align: center;
        margin-bottom: 16px;
      }
  
      .el-select {
        margin-bottom: 16px;
      }
  
      .form-checkbox {
        gap: 10px;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 20px;
      }
  
      .input__item-checkbox__title {
        font-size: 14px;
        line-height: 1.4;
      }
  
      .input__item-checkbox__check {
        margin-right: 4px;
      }
  
      .button {
        font-size: 14px;
        line-height: 1.4;
        height: auto;
        padding: 8px 12px;
        margin-top: 16px;
      }
    }
  }
  
  .button {
    margin-top: 3.5rem;
    width: 100%;
    height: 5rem;
  }
  
  .form {
  
    &-checkbox {
      display: flex;
      // align-items: center;
      // justify-content: space-between;
      margin: 2.5rem 0 0;
      flex-direction: column;
      gap: 1rem;
    }
  }
  
  .user-type {
    >input[type="radio"] { //for an input element with type="radio"
            position: absolute;
            z-index: -1;
            opacity: 0;
          }
          //for the label element associated with .settings-choice-item
          >span {       
            display: inline-flex;
            align-items: center;
            user-select: none;
            //creating a before pseudo-element in the label with the following styles
            &::before {
              content: '';
              display: inline-block;
              width: 1em;
              height: 1em;
              flex-shrink: 0;
              flex-grow: 0;
              border: 1px solid #adb5bd;
              border-radius: 50%;
              margin-right: 0.5em;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 50% 50%;
            }
          }
          //styling when hovering over the radio
          >input[type="radio"]:not(:disabled):not(:checked)+span:hover::before { 
            border-color: var(--primaryLight);
          }
          //styles for active radio button (when clicked)
          >input[type="radio"]:not(:disabled):active+span::before { 
            background-color: var(--primary);
            border-color: var(--primary);
            padding: 5px;
          }
          //styles for a radio button that is in the checked state
          >input[type="radio"]:checked+span::before {
            border-color: var(--primary);
            background-color: var(--primary);
          }
          //styles for a radio button that is in the disabled state
          >input[type="radio"]:disabled+span::before {
            background-color: #e9ecef;
          }
  }
  .checkbox_errors {
    margin-top: 1rem;
  }
  </style>
  